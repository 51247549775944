import { default as allA7lJgzh7z0Meta } from "/vercel/path0/pages/all.vue?macro=true";
import { default as _91operator_93XJQQ38f1FhMeta } from "/vercel/path0/pages/booking/isolated/[operator].vue?macro=true";
import { default as _91operator_93oND0H5VZpwMeta } from "/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue?macro=true";
import { default as BAKGH0fpMX29zMeta } from "/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue?macro=true";
import { default as _91operator_93BscvSYNqJDMeta } from "/vercel/path0/pages/booking/isolated/next/[operator].vue?macro=true";
import { default as _91id_939pNUMqq1pbMeta } from "/vercel/path0/pages/booking/isolated/payment/[id].vue?macro=true";
import { default as _91id_93pZzLRB2dYfMeta } from "/vercel/path0/pages/booking/isolated/view/[id].vue?macro=true";
import { default as testerV2ZQ2o1V31Meta } from "/vercel/path0/pages/booking/tester.vue?macro=true";
import { default as _91id_93TMJeJ1tOREMeta } from "/vercel/path0/pages/booking/view/[id].vue?macro=true";
import { default as bookings_45advancedunSG6On8iLMeta } from "/vercel/path0/pages/bookings-advanced.vue?macro=true";
import { default as bookingsA4rTxrKjRCMeta } from "/vercel/path0/pages/bookings.vue?macro=true";
import { default as indexM4Yf3RUVM2Meta } from "/vercel/path0/pages/check-in/index.vue?macro=true";
import { default as company_45settingssqEkq2B7yJMeta } from "/vercel/path0/pages/company-settings.vue?macro=true";
import { default as company_45teamemuB3wJ7qRMeta } from "/vercel/path0/pages/company-team.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as _91company_93jtr2GSmH5OMeta } from "/vercel/path0/pages/impersonate/[company].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as integrationsJZ3HPe1SD1Meta } from "/vercel/path0/pages/integrations.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as organisation_45settingseAm8powacpMeta } from "/vercel/path0/pages/organisation-settings.vue?macro=true";
import { default as bookingsAUVX3Ih5jJMeta } from "/vercel/path0/pages/organisation/bookings.vue?macro=true";
import { default as companiesOwmwcXKnF2Meta } from "/vercel/path0/pages/organisation/companies.vue?macro=true";
import { default as referralsQHOW64tZ8CMeta } from "/vercel/path0/pages/organisation/referrals.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as indexMQGDnvdRNaMeta } from "/vercel/path0/pages/report-tour-numbers/index.vue?macro=true";
import { default as thanks_45for_45joiningvvUmj3acUPMeta } from "/vercel/path0/pages/thanks-for-joining.vue?macro=true";
import { default as thanks_45for_45registeringTRXTFvFnXeMeta } from "/vercel/path0/pages/thanks-for-registering.vue?macro=true";
import { default as calendarHrdVuwaPTtMeta } from "/vercel/path0/pages/tours/calendar.vue?macro=true";
import { default as calendar24sg232nPPxMeta } from "/vercel/path0/pages/tours/calendar2.vue?macro=true";
import { default as indexemJKwxSY7PMeta } from "/vercel/path0/pages/tours/index.vue?macro=true";
import { default as indext6uh3LgzeAMeta } from "/vercel/path0/pages/tours/manage/index.vue?macro=true";
import { default as update_45passwordT8SgS9UmTaMeta } from "/vercel/path0/pages/update-password.vue?macro=true";
export default [
  {
    name: allA7lJgzh7z0Meta?.name ?? "all___en___default",
    path: allA7lJgzh7z0Meta?.path ?? "/all",
    meta: allA7lJgzh7z0Meta || {},
    alias: allA7lJgzh7z0Meta?.alias || [],
    redirect: allA7lJgzh7z0Meta?.redirect,
    component: () => import("/vercel/path0/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allA7lJgzh7z0Meta?.name ?? "all___en",
    path: allA7lJgzh7z0Meta?.path ?? "/en/all",
    meta: allA7lJgzh7z0Meta || {},
    alias: allA7lJgzh7z0Meta?.alias || [],
    redirect: allA7lJgzh7z0Meta?.redirect,
    component: () => import("/vercel/path0/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allA7lJgzh7z0Meta?.name ?? "all___es",
    path: allA7lJgzh7z0Meta?.path ?? "/es/all",
    meta: allA7lJgzh7z0Meta || {},
    alias: allA7lJgzh7z0Meta?.alias || [],
    redirect: allA7lJgzh7z0Meta?.redirect,
    component: () => import("/vercel/path0/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allA7lJgzh7z0Meta?.name ?? "all___da",
    path: allA7lJgzh7z0Meta?.path ?? "/da/all",
    meta: allA7lJgzh7z0Meta || {},
    alias: allA7lJgzh7z0Meta?.alias || [],
    redirect: allA7lJgzh7z0Meta?.redirect,
    component: () => import("/vercel/path0/pages/all.vue").then(m => m.default || m)
  },
  {
    name: allA7lJgzh7z0Meta?.name ?? "all___it",
    path: allA7lJgzh7z0Meta?.path ?? "/it/all",
    meta: allA7lJgzh7z0Meta || {},
    alias: allA7lJgzh7z0Meta?.alias || [],
    redirect: allA7lJgzh7z0Meta?.redirect,
    component: () => import("/vercel/path0/pages/all.vue").then(m => m.default || m)
  },
  {
    name: _91operator_93XJQQ38f1FhMeta?.name ?? "booking-isolated-operator___en___default",
    path: _91operator_93XJQQ38f1FhMeta?.path ?? "/booking/isolated/:operator()",
    meta: _91operator_93XJQQ38f1FhMeta || {},
    alias: _91operator_93XJQQ38f1FhMeta?.alias || [],
    redirect: _91operator_93XJQQ38f1FhMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93XJQQ38f1FhMeta?.name ?? "booking-isolated-operator___en",
    path: _91operator_93XJQQ38f1FhMeta?.path ?? "/en/booking/isolated/:operator()",
    meta: _91operator_93XJQQ38f1FhMeta || {},
    alias: _91operator_93XJQQ38f1FhMeta?.alias || [],
    redirect: _91operator_93XJQQ38f1FhMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93XJQQ38f1FhMeta?.name ?? "booking-isolated-operator___es",
    path: _91operator_93XJQQ38f1FhMeta?.path ?? "/es/booking/isolated/:operator()",
    meta: _91operator_93XJQQ38f1FhMeta || {},
    alias: _91operator_93XJQQ38f1FhMeta?.alias || [],
    redirect: _91operator_93XJQQ38f1FhMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93XJQQ38f1FhMeta?.name ?? "booking-isolated-operator___da",
    path: _91operator_93XJQQ38f1FhMeta?.path ?? "/da/booking/isolated/:operator()",
    meta: _91operator_93XJQQ38f1FhMeta || {},
    alias: _91operator_93XJQQ38f1FhMeta?.alias || [],
    redirect: _91operator_93XJQQ38f1FhMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93XJQQ38f1FhMeta?.name ?? "booking-isolated-operator___it",
    path: _91operator_93XJQQ38f1FhMeta?.path ?? "/it/booking/isolated/:operator()",
    meta: _91operator_93XJQQ38f1FhMeta || {},
    alias: _91operator_93XJQQ38f1FhMeta?.alias || [],
    redirect: _91operator_93XJQQ38f1FhMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93oND0H5VZpwMeta?.name ?? "booking-isolated-organisation-operator___en___default",
    path: _91operator_93oND0H5VZpwMeta?.path ?? "/booking/isolated/:organisation()/:operator()",
    meta: _91operator_93oND0H5VZpwMeta || {},
    alias: _91operator_93oND0H5VZpwMeta?.alias || [],
    redirect: _91operator_93oND0H5VZpwMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93oND0H5VZpwMeta?.name ?? "booking-isolated-organisation-operator___en",
    path: _91operator_93oND0H5VZpwMeta?.path ?? "/en/booking/isolated/:organisation()/:operator()",
    meta: _91operator_93oND0H5VZpwMeta || {},
    alias: _91operator_93oND0H5VZpwMeta?.alias || [],
    redirect: _91operator_93oND0H5VZpwMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93oND0H5VZpwMeta?.name ?? "booking-isolated-organisation-operator___es",
    path: _91operator_93oND0H5VZpwMeta?.path ?? "/es/booking/isolated/:organisation()/:operator()",
    meta: _91operator_93oND0H5VZpwMeta || {},
    alias: _91operator_93oND0H5VZpwMeta?.alias || [],
    redirect: _91operator_93oND0H5VZpwMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93oND0H5VZpwMeta?.name ?? "booking-isolated-organisation-operator___da",
    path: _91operator_93oND0H5VZpwMeta?.path ?? "/da/booking/isolated/:organisation()/:operator()",
    meta: _91operator_93oND0H5VZpwMeta || {},
    alias: _91operator_93oND0H5VZpwMeta?.alias || [],
    redirect: _91operator_93oND0H5VZpwMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93oND0H5VZpwMeta?.name ?? "booking-isolated-organisation-operator___it",
    path: _91operator_93oND0H5VZpwMeta?.path ?? "/it/booking/isolated/:organisation()/:operator()",
    meta: _91operator_93oND0H5VZpwMeta || {},
    alias: _91operator_93oND0H5VZpwMeta?.alias || [],
    redirect: _91operator_93oND0H5VZpwMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/[operator].vue").then(m => m.default || m)
  },
  {
    name: BAKGH0fpMX29zMeta?.name ?? "booking-isolated-organisation-BAK___en___default",
    path: BAKGH0fpMX29zMeta?.path ?? "/booking/isolated/:organisation()/BAK",
    meta: BAKGH0fpMX29zMeta || {},
    alias: BAKGH0fpMX29zMeta?.alias || [],
    redirect: BAKGH0fpMX29zMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue").then(m => m.default || m)
  },
  {
    name: BAKGH0fpMX29zMeta?.name ?? "booking-isolated-organisation-BAK___en",
    path: BAKGH0fpMX29zMeta?.path ?? "/en/booking/isolated/:organisation()/BAK",
    meta: BAKGH0fpMX29zMeta || {},
    alias: BAKGH0fpMX29zMeta?.alias || [],
    redirect: BAKGH0fpMX29zMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue").then(m => m.default || m)
  },
  {
    name: BAKGH0fpMX29zMeta?.name ?? "booking-isolated-organisation-BAK___es",
    path: BAKGH0fpMX29zMeta?.path ?? "/es/booking/isolated/:organisation()/BAK",
    meta: BAKGH0fpMX29zMeta || {},
    alias: BAKGH0fpMX29zMeta?.alias || [],
    redirect: BAKGH0fpMX29zMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue").then(m => m.default || m)
  },
  {
    name: BAKGH0fpMX29zMeta?.name ?? "booking-isolated-organisation-BAK___da",
    path: BAKGH0fpMX29zMeta?.path ?? "/da/booking/isolated/:organisation()/BAK",
    meta: BAKGH0fpMX29zMeta || {},
    alias: BAKGH0fpMX29zMeta?.alias || [],
    redirect: BAKGH0fpMX29zMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue").then(m => m.default || m)
  },
  {
    name: BAKGH0fpMX29zMeta?.name ?? "booking-isolated-organisation-BAK___it",
    path: BAKGH0fpMX29zMeta?.path ?? "/it/booking/isolated/:organisation()/BAK",
    meta: BAKGH0fpMX29zMeta || {},
    alias: BAKGH0fpMX29zMeta?.alias || [],
    redirect: BAKGH0fpMX29zMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/[organisation]/BAK.vue").then(m => m.default || m)
  },
  {
    name: _91operator_93BscvSYNqJDMeta?.name ?? "booking-isolated-next-operator___en___default",
    path: _91operator_93BscvSYNqJDMeta?.path ?? "/booking/isolated/next/:operator()",
    meta: _91operator_93BscvSYNqJDMeta || {},
    alias: _91operator_93BscvSYNqJDMeta?.alias || [],
    redirect: _91operator_93BscvSYNqJDMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/next/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93BscvSYNqJDMeta?.name ?? "booking-isolated-next-operator___en",
    path: _91operator_93BscvSYNqJDMeta?.path ?? "/en/booking/isolated/next/:operator()",
    meta: _91operator_93BscvSYNqJDMeta || {},
    alias: _91operator_93BscvSYNqJDMeta?.alias || [],
    redirect: _91operator_93BscvSYNqJDMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/next/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93BscvSYNqJDMeta?.name ?? "booking-isolated-next-operator___es",
    path: _91operator_93BscvSYNqJDMeta?.path ?? "/es/booking/isolated/next/:operator()",
    meta: _91operator_93BscvSYNqJDMeta || {},
    alias: _91operator_93BscvSYNqJDMeta?.alias || [],
    redirect: _91operator_93BscvSYNqJDMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/next/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93BscvSYNqJDMeta?.name ?? "booking-isolated-next-operator___da",
    path: _91operator_93BscvSYNqJDMeta?.path ?? "/da/booking/isolated/next/:operator()",
    meta: _91operator_93BscvSYNqJDMeta || {},
    alias: _91operator_93BscvSYNqJDMeta?.alias || [],
    redirect: _91operator_93BscvSYNqJDMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/next/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91operator_93BscvSYNqJDMeta?.name ?? "booking-isolated-next-operator___it",
    path: _91operator_93BscvSYNqJDMeta?.path ?? "/it/booking/isolated/next/:operator()",
    meta: _91operator_93BscvSYNqJDMeta || {},
    alias: _91operator_93BscvSYNqJDMeta?.alias || [],
    redirect: _91operator_93BscvSYNqJDMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/next/[operator].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pNUMqq1pbMeta?.name ?? "booking-isolated-payment-id___en___default",
    path: _91id_939pNUMqq1pbMeta?.path ?? "/booking/isolated/payment/:id()",
    meta: _91id_939pNUMqq1pbMeta || {},
    alias: _91id_939pNUMqq1pbMeta?.alias || [],
    redirect: _91id_939pNUMqq1pbMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pNUMqq1pbMeta?.name ?? "booking-isolated-payment-id___en",
    path: _91id_939pNUMqq1pbMeta?.path ?? "/en/booking/isolated/payment/:id()",
    meta: _91id_939pNUMqq1pbMeta || {},
    alias: _91id_939pNUMqq1pbMeta?.alias || [],
    redirect: _91id_939pNUMqq1pbMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pNUMqq1pbMeta?.name ?? "booking-isolated-payment-id___es",
    path: _91id_939pNUMqq1pbMeta?.path ?? "/es/booking/isolated/payment/:id()",
    meta: _91id_939pNUMqq1pbMeta || {},
    alias: _91id_939pNUMqq1pbMeta?.alias || [],
    redirect: _91id_939pNUMqq1pbMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pNUMqq1pbMeta?.name ?? "booking-isolated-payment-id___da",
    path: _91id_939pNUMqq1pbMeta?.path ?? "/da/booking/isolated/payment/:id()",
    meta: _91id_939pNUMqq1pbMeta || {},
    alias: _91id_939pNUMqq1pbMeta?.alias || [],
    redirect: _91id_939pNUMqq1pbMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pNUMqq1pbMeta?.name ?? "booking-isolated-payment-id___it",
    path: _91id_939pNUMqq1pbMeta?.path ?? "/it/booking/isolated/payment/:id()",
    meta: _91id_939pNUMqq1pbMeta || {},
    alias: _91id_939pNUMqq1pbMeta?.alias || [],
    redirect: _91id_939pNUMqq1pbMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pZzLRB2dYfMeta?.name ?? "booking-isolated-view-id___en___default",
    path: _91id_93pZzLRB2dYfMeta?.path ?? "/booking/isolated/view/:id()",
    meta: _91id_93pZzLRB2dYfMeta || {},
    alias: _91id_93pZzLRB2dYfMeta?.alias || [],
    redirect: _91id_93pZzLRB2dYfMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pZzLRB2dYfMeta?.name ?? "booking-isolated-view-id___en",
    path: _91id_93pZzLRB2dYfMeta?.path ?? "/en/booking/isolated/view/:id()",
    meta: _91id_93pZzLRB2dYfMeta || {},
    alias: _91id_93pZzLRB2dYfMeta?.alias || [],
    redirect: _91id_93pZzLRB2dYfMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pZzLRB2dYfMeta?.name ?? "booking-isolated-view-id___es",
    path: _91id_93pZzLRB2dYfMeta?.path ?? "/es/booking/isolated/view/:id()",
    meta: _91id_93pZzLRB2dYfMeta || {},
    alias: _91id_93pZzLRB2dYfMeta?.alias || [],
    redirect: _91id_93pZzLRB2dYfMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pZzLRB2dYfMeta?.name ?? "booking-isolated-view-id___da",
    path: _91id_93pZzLRB2dYfMeta?.path ?? "/da/booking/isolated/view/:id()",
    meta: _91id_93pZzLRB2dYfMeta || {},
    alias: _91id_93pZzLRB2dYfMeta?.alias || [],
    redirect: _91id_93pZzLRB2dYfMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pZzLRB2dYfMeta?.name ?? "booking-isolated-view-id___it",
    path: _91id_93pZzLRB2dYfMeta?.path ?? "/it/booking/isolated/view/:id()",
    meta: _91id_93pZzLRB2dYfMeta || {},
    alias: _91id_93pZzLRB2dYfMeta?.alias || [],
    redirect: _91id_93pZzLRB2dYfMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/isolated/view/[id].vue").then(m => m.default || m)
  },
  {
    name: testerV2ZQ2o1V31Meta?.name ?? "booking-tester___en___default",
    path: testerV2ZQ2o1V31Meta?.path ?? "/booking/tester",
    meta: testerV2ZQ2o1V31Meta || {},
    alias: testerV2ZQ2o1V31Meta?.alias || [],
    redirect: testerV2ZQ2o1V31Meta?.redirect,
    component: () => import("/vercel/path0/pages/booking/tester.vue").then(m => m.default || m)
  },
  {
    name: testerV2ZQ2o1V31Meta?.name ?? "booking-tester___en",
    path: testerV2ZQ2o1V31Meta?.path ?? "/en/booking/tester",
    meta: testerV2ZQ2o1V31Meta || {},
    alias: testerV2ZQ2o1V31Meta?.alias || [],
    redirect: testerV2ZQ2o1V31Meta?.redirect,
    component: () => import("/vercel/path0/pages/booking/tester.vue").then(m => m.default || m)
  },
  {
    name: testerV2ZQ2o1V31Meta?.name ?? "booking-tester___es",
    path: testerV2ZQ2o1V31Meta?.path ?? "/es/booking/tester",
    meta: testerV2ZQ2o1V31Meta || {},
    alias: testerV2ZQ2o1V31Meta?.alias || [],
    redirect: testerV2ZQ2o1V31Meta?.redirect,
    component: () => import("/vercel/path0/pages/booking/tester.vue").then(m => m.default || m)
  },
  {
    name: testerV2ZQ2o1V31Meta?.name ?? "booking-tester___da",
    path: testerV2ZQ2o1V31Meta?.path ?? "/da/booking/tester",
    meta: testerV2ZQ2o1V31Meta || {},
    alias: testerV2ZQ2o1V31Meta?.alias || [],
    redirect: testerV2ZQ2o1V31Meta?.redirect,
    component: () => import("/vercel/path0/pages/booking/tester.vue").then(m => m.default || m)
  },
  {
    name: testerV2ZQ2o1V31Meta?.name ?? "booking-tester___it",
    path: testerV2ZQ2o1V31Meta?.path ?? "/it/booking/tester",
    meta: testerV2ZQ2o1V31Meta || {},
    alias: testerV2ZQ2o1V31Meta?.alias || [],
    redirect: testerV2ZQ2o1V31Meta?.redirect,
    component: () => import("/vercel/path0/pages/booking/tester.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TMJeJ1tOREMeta?.name ?? "booking-view-id___en___default",
    path: _91id_93TMJeJ1tOREMeta?.path ?? "/booking/view/:id()",
    meta: _91id_93TMJeJ1tOREMeta || {},
    alias: _91id_93TMJeJ1tOREMeta?.alias || [],
    redirect: _91id_93TMJeJ1tOREMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TMJeJ1tOREMeta?.name ?? "booking-view-id___en",
    path: _91id_93TMJeJ1tOREMeta?.path ?? "/en/booking/view/:id()",
    meta: _91id_93TMJeJ1tOREMeta || {},
    alias: _91id_93TMJeJ1tOREMeta?.alias || [],
    redirect: _91id_93TMJeJ1tOREMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TMJeJ1tOREMeta?.name ?? "booking-view-id___es",
    path: _91id_93TMJeJ1tOREMeta?.path ?? "/es/booking/view/:id()",
    meta: _91id_93TMJeJ1tOREMeta || {},
    alias: _91id_93TMJeJ1tOREMeta?.alias || [],
    redirect: _91id_93TMJeJ1tOREMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TMJeJ1tOREMeta?.name ?? "booking-view-id___da",
    path: _91id_93TMJeJ1tOREMeta?.path ?? "/da/booking/view/:id()",
    meta: _91id_93TMJeJ1tOREMeta || {},
    alias: _91id_93TMJeJ1tOREMeta?.alias || [],
    redirect: _91id_93TMJeJ1tOREMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TMJeJ1tOREMeta?.name ?? "booking-view-id___it",
    path: _91id_93TMJeJ1tOREMeta?.path ?? "/it/booking/view/:id()",
    meta: _91id_93TMJeJ1tOREMeta || {},
    alias: _91id_93TMJeJ1tOREMeta?.alias || [],
    redirect: _91id_93TMJeJ1tOREMeta?.redirect,
    component: () => import("/vercel/path0/pages/booking/view/[id].vue").then(m => m.default || m)
  },
  {
    name: bookings_45advancedunSG6On8iLMeta?.name ?? "bookings-advanced___en___default",
    path: bookings_45advancedunSG6On8iLMeta?.path ?? "/bookings-advanced",
    meta: bookings_45advancedunSG6On8iLMeta || {},
    alias: bookings_45advancedunSG6On8iLMeta?.alias || [],
    redirect: bookings_45advancedunSG6On8iLMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings-advanced.vue").then(m => m.default || m)
  },
  {
    name: bookings_45advancedunSG6On8iLMeta?.name ?? "bookings-advanced___en",
    path: bookings_45advancedunSG6On8iLMeta?.path ?? "/en/bookings-advanced",
    meta: bookings_45advancedunSG6On8iLMeta || {},
    alias: bookings_45advancedunSG6On8iLMeta?.alias || [],
    redirect: bookings_45advancedunSG6On8iLMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings-advanced.vue").then(m => m.default || m)
  },
  {
    name: bookings_45advancedunSG6On8iLMeta?.name ?? "bookings-advanced___es",
    path: bookings_45advancedunSG6On8iLMeta?.path ?? "/es/bookings-advanced",
    meta: bookings_45advancedunSG6On8iLMeta || {},
    alias: bookings_45advancedunSG6On8iLMeta?.alias || [],
    redirect: bookings_45advancedunSG6On8iLMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings-advanced.vue").then(m => m.default || m)
  },
  {
    name: bookings_45advancedunSG6On8iLMeta?.name ?? "bookings-advanced___da",
    path: bookings_45advancedunSG6On8iLMeta?.path ?? "/da/bookings-advanced",
    meta: bookings_45advancedunSG6On8iLMeta || {},
    alias: bookings_45advancedunSG6On8iLMeta?.alias || [],
    redirect: bookings_45advancedunSG6On8iLMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings-advanced.vue").then(m => m.default || m)
  },
  {
    name: bookings_45advancedunSG6On8iLMeta?.name ?? "bookings-advanced___it",
    path: bookings_45advancedunSG6On8iLMeta?.path ?? "/it/bookings-advanced",
    meta: bookings_45advancedunSG6On8iLMeta || {},
    alias: bookings_45advancedunSG6On8iLMeta?.alias || [],
    redirect: bookings_45advancedunSG6On8iLMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings-advanced.vue").then(m => m.default || m)
  },
  {
    name: bookingsA4rTxrKjRCMeta?.name ?? "bookings___en___default",
    path: bookingsA4rTxrKjRCMeta?.path ?? "/bookings",
    meta: bookingsA4rTxrKjRCMeta || {},
    alias: bookingsA4rTxrKjRCMeta?.alias || [],
    redirect: bookingsA4rTxrKjRCMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsA4rTxrKjRCMeta?.name ?? "bookings___en",
    path: bookingsA4rTxrKjRCMeta?.path ?? "/en/bookings",
    meta: bookingsA4rTxrKjRCMeta || {},
    alias: bookingsA4rTxrKjRCMeta?.alias || [],
    redirect: bookingsA4rTxrKjRCMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsA4rTxrKjRCMeta?.name ?? "bookings___es",
    path: bookingsA4rTxrKjRCMeta?.path ?? "/es/bookings",
    meta: bookingsA4rTxrKjRCMeta || {},
    alias: bookingsA4rTxrKjRCMeta?.alias || [],
    redirect: bookingsA4rTxrKjRCMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsA4rTxrKjRCMeta?.name ?? "bookings___da",
    path: bookingsA4rTxrKjRCMeta?.path ?? "/da/bookings",
    meta: bookingsA4rTxrKjRCMeta || {},
    alias: bookingsA4rTxrKjRCMeta?.alias || [],
    redirect: bookingsA4rTxrKjRCMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsA4rTxrKjRCMeta?.name ?? "bookings___it",
    path: bookingsA4rTxrKjRCMeta?.path ?? "/it/bookings",
    meta: bookingsA4rTxrKjRCMeta || {},
    alias: bookingsA4rTxrKjRCMeta?.alias || [],
    redirect: bookingsA4rTxrKjRCMeta?.redirect,
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: indexM4Yf3RUVM2Meta?.name ?? "check-in___en___default",
    path: indexM4Yf3RUVM2Meta?.path ?? "/check-in",
    meta: indexM4Yf3RUVM2Meta || {},
    alias: indexM4Yf3RUVM2Meta?.alias || [],
    redirect: indexM4Yf3RUVM2Meta?.redirect,
    component: () => import("/vercel/path0/pages/check-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4Yf3RUVM2Meta?.name ?? "check-in___en",
    path: indexM4Yf3RUVM2Meta?.path ?? "/en/check-in",
    meta: indexM4Yf3RUVM2Meta || {},
    alias: indexM4Yf3RUVM2Meta?.alias || [],
    redirect: indexM4Yf3RUVM2Meta?.redirect,
    component: () => import("/vercel/path0/pages/check-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4Yf3RUVM2Meta?.name ?? "check-in___es",
    path: indexM4Yf3RUVM2Meta?.path ?? "/es/check-in",
    meta: indexM4Yf3RUVM2Meta || {},
    alias: indexM4Yf3RUVM2Meta?.alias || [],
    redirect: indexM4Yf3RUVM2Meta?.redirect,
    component: () => import("/vercel/path0/pages/check-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4Yf3RUVM2Meta?.name ?? "check-in___da",
    path: indexM4Yf3RUVM2Meta?.path ?? "/da/check-in",
    meta: indexM4Yf3RUVM2Meta || {},
    alias: indexM4Yf3RUVM2Meta?.alias || [],
    redirect: indexM4Yf3RUVM2Meta?.redirect,
    component: () => import("/vercel/path0/pages/check-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4Yf3RUVM2Meta?.name ?? "check-in___it",
    path: indexM4Yf3RUVM2Meta?.path ?? "/it/check-in",
    meta: indexM4Yf3RUVM2Meta || {},
    alias: indexM4Yf3RUVM2Meta?.alias || [],
    redirect: indexM4Yf3RUVM2Meta?.redirect,
    component: () => import("/vercel/path0/pages/check-in/index.vue").then(m => m.default || m)
  },
  {
    name: company_45settingssqEkq2B7yJMeta?.name ?? "company-settings___en___default",
    path: company_45settingssqEkq2B7yJMeta?.path ?? "/company-settings",
    meta: company_45settingssqEkq2B7yJMeta || {},
    alias: company_45settingssqEkq2B7yJMeta?.alias || [],
    redirect: company_45settingssqEkq2B7yJMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-settings.vue").then(m => m.default || m)
  },
  {
    name: company_45settingssqEkq2B7yJMeta?.name ?? "company-settings___en",
    path: company_45settingssqEkq2B7yJMeta?.path ?? "/en/company-settings",
    meta: company_45settingssqEkq2B7yJMeta || {},
    alias: company_45settingssqEkq2B7yJMeta?.alias || [],
    redirect: company_45settingssqEkq2B7yJMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-settings.vue").then(m => m.default || m)
  },
  {
    name: company_45settingssqEkq2B7yJMeta?.name ?? "company-settings___es",
    path: company_45settingssqEkq2B7yJMeta?.path ?? "/es/company-settings",
    meta: company_45settingssqEkq2B7yJMeta || {},
    alias: company_45settingssqEkq2B7yJMeta?.alias || [],
    redirect: company_45settingssqEkq2B7yJMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-settings.vue").then(m => m.default || m)
  },
  {
    name: company_45settingssqEkq2B7yJMeta?.name ?? "company-settings___da",
    path: company_45settingssqEkq2B7yJMeta?.path ?? "/da/company-settings",
    meta: company_45settingssqEkq2B7yJMeta || {},
    alias: company_45settingssqEkq2B7yJMeta?.alias || [],
    redirect: company_45settingssqEkq2B7yJMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-settings.vue").then(m => m.default || m)
  },
  {
    name: company_45settingssqEkq2B7yJMeta?.name ?? "company-settings___it",
    path: company_45settingssqEkq2B7yJMeta?.path ?? "/it/company-settings",
    meta: company_45settingssqEkq2B7yJMeta || {},
    alias: company_45settingssqEkq2B7yJMeta?.alias || [],
    redirect: company_45settingssqEkq2B7yJMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-settings.vue").then(m => m.default || m)
  },
  {
    name: company_45teamemuB3wJ7qRMeta?.name ?? "company-team___en___default",
    path: company_45teamemuB3wJ7qRMeta?.path ?? "/company-team",
    meta: company_45teamemuB3wJ7qRMeta || {},
    alias: company_45teamemuB3wJ7qRMeta?.alias || [],
    redirect: company_45teamemuB3wJ7qRMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-team.vue").then(m => m.default || m)
  },
  {
    name: company_45teamemuB3wJ7qRMeta?.name ?? "company-team___en",
    path: company_45teamemuB3wJ7qRMeta?.path ?? "/en/company-team",
    meta: company_45teamemuB3wJ7qRMeta || {},
    alias: company_45teamemuB3wJ7qRMeta?.alias || [],
    redirect: company_45teamemuB3wJ7qRMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-team.vue").then(m => m.default || m)
  },
  {
    name: company_45teamemuB3wJ7qRMeta?.name ?? "company-team___es",
    path: company_45teamemuB3wJ7qRMeta?.path ?? "/es/company-team",
    meta: company_45teamemuB3wJ7qRMeta || {},
    alias: company_45teamemuB3wJ7qRMeta?.alias || [],
    redirect: company_45teamemuB3wJ7qRMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-team.vue").then(m => m.default || m)
  },
  {
    name: company_45teamemuB3wJ7qRMeta?.name ?? "company-team___da",
    path: company_45teamemuB3wJ7qRMeta?.path ?? "/da/company-team",
    meta: company_45teamemuB3wJ7qRMeta || {},
    alias: company_45teamemuB3wJ7qRMeta?.alias || [],
    redirect: company_45teamemuB3wJ7qRMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-team.vue").then(m => m.default || m)
  },
  {
    name: company_45teamemuB3wJ7qRMeta?.name ?? "company-team___it",
    path: company_45teamemuB3wJ7qRMeta?.path ?? "/it/company-team",
    meta: company_45teamemuB3wJ7qRMeta || {},
    alias: company_45teamemuB3wJ7qRMeta?.alias || [],
    redirect: company_45teamemuB3wJ7qRMeta?.redirect,
    component: () => import("/vercel/path0/pages/company-team.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___en___default",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___en",
    path: dashboardHdtnClvoGAMeta?.path ?? "/en/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___es",
    path: dashboardHdtnClvoGAMeta?.path ?? "/es/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___da",
    path: dashboardHdtnClvoGAMeta?.path ?? "/da/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard___it",
    path: dashboardHdtnClvoGAMeta?.path ?? "/it/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password___en___default",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password___en",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/en/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password___es",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/es/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password___da",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/da/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password___it",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/it/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91company_93jtr2GSmH5OMeta?.name ?? "impersonate-company___en___default",
    path: _91company_93jtr2GSmH5OMeta?.path ?? "/impersonate/:company()",
    meta: _91company_93jtr2GSmH5OMeta || {},
    alias: _91company_93jtr2GSmH5OMeta?.alias || [],
    redirect: _91company_93jtr2GSmH5OMeta?.redirect,
    component: () => import("/vercel/path0/pages/impersonate/[company].vue").then(m => m.default || m)
  },
  {
    name: _91company_93jtr2GSmH5OMeta?.name ?? "impersonate-company___en",
    path: _91company_93jtr2GSmH5OMeta?.path ?? "/en/impersonate/:company()",
    meta: _91company_93jtr2GSmH5OMeta || {},
    alias: _91company_93jtr2GSmH5OMeta?.alias || [],
    redirect: _91company_93jtr2GSmH5OMeta?.redirect,
    component: () => import("/vercel/path0/pages/impersonate/[company].vue").then(m => m.default || m)
  },
  {
    name: _91company_93jtr2GSmH5OMeta?.name ?? "impersonate-company___es",
    path: _91company_93jtr2GSmH5OMeta?.path ?? "/es/impersonate/:company()",
    meta: _91company_93jtr2GSmH5OMeta || {},
    alias: _91company_93jtr2GSmH5OMeta?.alias || [],
    redirect: _91company_93jtr2GSmH5OMeta?.redirect,
    component: () => import("/vercel/path0/pages/impersonate/[company].vue").then(m => m.default || m)
  },
  {
    name: _91company_93jtr2GSmH5OMeta?.name ?? "impersonate-company___da",
    path: _91company_93jtr2GSmH5OMeta?.path ?? "/da/impersonate/:company()",
    meta: _91company_93jtr2GSmH5OMeta || {},
    alias: _91company_93jtr2GSmH5OMeta?.alias || [],
    redirect: _91company_93jtr2GSmH5OMeta?.redirect,
    component: () => import("/vercel/path0/pages/impersonate/[company].vue").then(m => m.default || m)
  },
  {
    name: _91company_93jtr2GSmH5OMeta?.name ?? "impersonate-company___it",
    path: _91company_93jtr2GSmH5OMeta?.path ?? "/it/impersonate/:company()",
    meta: _91company_93jtr2GSmH5OMeta || {},
    alias: _91company_93jtr2GSmH5OMeta?.alias || [],
    redirect: _91company_93jtr2GSmH5OMeta?.redirect,
    component: () => import("/vercel/path0/pages/impersonate/[company].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en___default",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___es",
    path: indexe9Brt5DfdhMeta?.path ?? "/es",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___da",
    path: indexe9Brt5DfdhMeta?.path ?? "/da",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___it",
    path: indexe9Brt5DfdhMeta?.path ?? "/it",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsJZ3HPe1SD1Meta?.name ?? "integrations___en___default",
    path: integrationsJZ3HPe1SD1Meta?.path ?? "/integrations",
    meta: integrationsJZ3HPe1SD1Meta || {},
    alias: integrationsJZ3HPe1SD1Meta?.alias || [],
    redirect: integrationsJZ3HPe1SD1Meta?.redirect,
    component: () => import("/vercel/path0/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: integrationsJZ3HPe1SD1Meta?.name ?? "integrations___en",
    path: integrationsJZ3HPe1SD1Meta?.path ?? "/en/integrations",
    meta: integrationsJZ3HPe1SD1Meta || {},
    alias: integrationsJZ3HPe1SD1Meta?.alias || [],
    redirect: integrationsJZ3HPe1SD1Meta?.redirect,
    component: () => import("/vercel/path0/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: integrationsJZ3HPe1SD1Meta?.name ?? "integrations___es",
    path: integrationsJZ3HPe1SD1Meta?.path ?? "/es/integrations",
    meta: integrationsJZ3HPe1SD1Meta || {},
    alias: integrationsJZ3HPe1SD1Meta?.alias || [],
    redirect: integrationsJZ3HPe1SD1Meta?.redirect,
    component: () => import("/vercel/path0/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: integrationsJZ3HPe1SD1Meta?.name ?? "integrations___da",
    path: integrationsJZ3HPe1SD1Meta?.path ?? "/da/integrations",
    meta: integrationsJZ3HPe1SD1Meta || {},
    alias: integrationsJZ3HPe1SD1Meta?.alias || [],
    redirect: integrationsJZ3HPe1SD1Meta?.redirect,
    component: () => import("/vercel/path0/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: integrationsJZ3HPe1SD1Meta?.name ?? "integrations___it",
    path: integrationsJZ3HPe1SD1Meta?.path ?? "/it/integrations",
    meta: integrationsJZ3HPe1SD1Meta || {},
    alias: integrationsJZ3HPe1SD1Meta?.alias || [],
    redirect: integrationsJZ3HPe1SD1Meta?.redirect,
    component: () => import("/vercel/path0/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___en___default",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___en",
    path: logint0AWlhQgM0Meta?.path ?? "/en/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___es",
    path: logint0AWlhQgM0Meta?.path ?? "/es/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___da",
    path: logint0AWlhQgM0Meta?.path ?? "/da/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login___it",
    path: logint0AWlhQgM0Meta?.path ?? "/it/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: organisation_45settingseAm8powacpMeta?.name ?? "organisation-settings___en___default",
    path: organisation_45settingseAm8powacpMeta?.path ?? "/organisation-settings",
    meta: organisation_45settingseAm8powacpMeta || {},
    alias: organisation_45settingseAm8powacpMeta?.alias || [],
    redirect: organisation_45settingseAm8powacpMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation-settings.vue").then(m => m.default || m)
  },
  {
    name: organisation_45settingseAm8powacpMeta?.name ?? "organisation-settings___en",
    path: organisation_45settingseAm8powacpMeta?.path ?? "/en/organisation-settings",
    meta: organisation_45settingseAm8powacpMeta || {},
    alias: organisation_45settingseAm8powacpMeta?.alias || [],
    redirect: organisation_45settingseAm8powacpMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation-settings.vue").then(m => m.default || m)
  },
  {
    name: organisation_45settingseAm8powacpMeta?.name ?? "organisation-settings___es",
    path: organisation_45settingseAm8powacpMeta?.path ?? "/es/organisation-settings",
    meta: organisation_45settingseAm8powacpMeta || {},
    alias: organisation_45settingseAm8powacpMeta?.alias || [],
    redirect: organisation_45settingseAm8powacpMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation-settings.vue").then(m => m.default || m)
  },
  {
    name: organisation_45settingseAm8powacpMeta?.name ?? "organisation-settings___da",
    path: organisation_45settingseAm8powacpMeta?.path ?? "/da/organisation-settings",
    meta: organisation_45settingseAm8powacpMeta || {},
    alias: organisation_45settingseAm8powacpMeta?.alias || [],
    redirect: organisation_45settingseAm8powacpMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation-settings.vue").then(m => m.default || m)
  },
  {
    name: organisation_45settingseAm8powacpMeta?.name ?? "organisation-settings___it",
    path: organisation_45settingseAm8powacpMeta?.path ?? "/it/organisation-settings",
    meta: organisation_45settingseAm8powacpMeta || {},
    alias: organisation_45settingseAm8powacpMeta?.alias || [],
    redirect: organisation_45settingseAm8powacpMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation-settings.vue").then(m => m.default || m)
  },
  {
    name: bookingsAUVX3Ih5jJMeta?.name ?? "organisation-bookings___en___default",
    path: bookingsAUVX3Ih5jJMeta?.path ?? "/organisation/bookings",
    meta: bookingsAUVX3Ih5jJMeta || {},
    alias: bookingsAUVX3Ih5jJMeta?.alias || [],
    redirect: bookingsAUVX3Ih5jJMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsAUVX3Ih5jJMeta?.name ?? "organisation-bookings___en",
    path: bookingsAUVX3Ih5jJMeta?.path ?? "/en/organisation/bookings",
    meta: bookingsAUVX3Ih5jJMeta || {},
    alias: bookingsAUVX3Ih5jJMeta?.alias || [],
    redirect: bookingsAUVX3Ih5jJMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsAUVX3Ih5jJMeta?.name ?? "organisation-bookings___es",
    path: bookingsAUVX3Ih5jJMeta?.path ?? "/es/organisation/bookings",
    meta: bookingsAUVX3Ih5jJMeta || {},
    alias: bookingsAUVX3Ih5jJMeta?.alias || [],
    redirect: bookingsAUVX3Ih5jJMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsAUVX3Ih5jJMeta?.name ?? "organisation-bookings___da",
    path: bookingsAUVX3Ih5jJMeta?.path ?? "/da/organisation/bookings",
    meta: bookingsAUVX3Ih5jJMeta || {},
    alias: bookingsAUVX3Ih5jJMeta?.alias || [],
    redirect: bookingsAUVX3Ih5jJMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/bookings.vue").then(m => m.default || m)
  },
  {
    name: bookingsAUVX3Ih5jJMeta?.name ?? "organisation-bookings___it",
    path: bookingsAUVX3Ih5jJMeta?.path ?? "/it/organisation/bookings",
    meta: bookingsAUVX3Ih5jJMeta || {},
    alias: bookingsAUVX3Ih5jJMeta?.alias || [],
    redirect: bookingsAUVX3Ih5jJMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/bookings.vue").then(m => m.default || m)
  },
  {
    name: companiesOwmwcXKnF2Meta?.name ?? "organisation-companies___en___default",
    path: companiesOwmwcXKnF2Meta?.path ?? "/organisation/companies",
    meta: companiesOwmwcXKnF2Meta || {},
    alias: companiesOwmwcXKnF2Meta?.alias || [],
    redirect: companiesOwmwcXKnF2Meta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesOwmwcXKnF2Meta?.name ?? "organisation-companies___en",
    path: companiesOwmwcXKnF2Meta?.path ?? "/en/organisation/companies",
    meta: companiesOwmwcXKnF2Meta || {},
    alias: companiesOwmwcXKnF2Meta?.alias || [],
    redirect: companiesOwmwcXKnF2Meta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesOwmwcXKnF2Meta?.name ?? "organisation-companies___es",
    path: companiesOwmwcXKnF2Meta?.path ?? "/es/organisation/companies",
    meta: companiesOwmwcXKnF2Meta || {},
    alias: companiesOwmwcXKnF2Meta?.alias || [],
    redirect: companiesOwmwcXKnF2Meta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesOwmwcXKnF2Meta?.name ?? "organisation-companies___da",
    path: companiesOwmwcXKnF2Meta?.path ?? "/da/organisation/companies",
    meta: companiesOwmwcXKnF2Meta || {},
    alias: companiesOwmwcXKnF2Meta?.alias || [],
    redirect: companiesOwmwcXKnF2Meta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesOwmwcXKnF2Meta?.name ?? "organisation-companies___it",
    path: companiesOwmwcXKnF2Meta?.path ?? "/it/organisation/companies",
    meta: companiesOwmwcXKnF2Meta || {},
    alias: companiesOwmwcXKnF2Meta?.alias || [],
    redirect: companiesOwmwcXKnF2Meta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/companies.vue").then(m => m.default || m)
  },
  {
    name: referralsQHOW64tZ8CMeta?.name ?? "organisation-referrals___en___default",
    path: referralsQHOW64tZ8CMeta?.path ?? "/organisation/referrals",
    meta: referralsQHOW64tZ8CMeta || {},
    alias: referralsQHOW64tZ8CMeta?.alias || [],
    redirect: referralsQHOW64tZ8CMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsQHOW64tZ8CMeta?.name ?? "organisation-referrals___en",
    path: referralsQHOW64tZ8CMeta?.path ?? "/en/organisation/referrals",
    meta: referralsQHOW64tZ8CMeta || {},
    alias: referralsQHOW64tZ8CMeta?.alias || [],
    redirect: referralsQHOW64tZ8CMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsQHOW64tZ8CMeta?.name ?? "organisation-referrals___es",
    path: referralsQHOW64tZ8CMeta?.path ?? "/es/organisation/referrals",
    meta: referralsQHOW64tZ8CMeta || {},
    alias: referralsQHOW64tZ8CMeta?.alias || [],
    redirect: referralsQHOW64tZ8CMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsQHOW64tZ8CMeta?.name ?? "organisation-referrals___da",
    path: referralsQHOW64tZ8CMeta?.path ?? "/da/organisation/referrals",
    meta: referralsQHOW64tZ8CMeta || {},
    alias: referralsQHOW64tZ8CMeta?.alias || [],
    redirect: referralsQHOW64tZ8CMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsQHOW64tZ8CMeta?.name ?? "organisation-referrals___it",
    path: referralsQHOW64tZ8CMeta?.path ?? "/it/organisation/referrals",
    meta: referralsQHOW64tZ8CMeta || {},
    alias: referralsQHOW64tZ8CMeta?.alias || [],
    redirect: referralsQHOW64tZ8CMeta?.redirect,
    component: () => import("/vercel/path0/pages/organisation/referrals.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___en___default",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___en",
    path: registerIikvtlfpbHMeta?.path ?? "/en/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___es",
    path: registerIikvtlfpbHMeta?.path ?? "/es/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___da",
    path: registerIikvtlfpbHMeta?.path ?? "/da/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register___it",
    path: registerIikvtlfpbHMeta?.path ?? "/it/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexMQGDnvdRNaMeta?.name ?? "report-tour-numbers___en___default",
    path: indexMQGDnvdRNaMeta?.path ?? "/report-tour-numbers",
    meta: indexMQGDnvdRNaMeta || {},
    alias: indexMQGDnvdRNaMeta?.alias || [],
    redirect: indexMQGDnvdRNaMeta?.redirect,
    component: () => import("/vercel/path0/pages/report-tour-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQGDnvdRNaMeta?.name ?? "report-tour-numbers___en",
    path: indexMQGDnvdRNaMeta?.path ?? "/en/report-tour-numbers",
    meta: indexMQGDnvdRNaMeta || {},
    alias: indexMQGDnvdRNaMeta?.alias || [],
    redirect: indexMQGDnvdRNaMeta?.redirect,
    component: () => import("/vercel/path0/pages/report-tour-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQGDnvdRNaMeta?.name ?? "report-tour-numbers___es",
    path: indexMQGDnvdRNaMeta?.path ?? "/es/report-tour-numbers",
    meta: indexMQGDnvdRNaMeta || {},
    alias: indexMQGDnvdRNaMeta?.alias || [],
    redirect: indexMQGDnvdRNaMeta?.redirect,
    component: () => import("/vercel/path0/pages/report-tour-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQGDnvdRNaMeta?.name ?? "report-tour-numbers___da",
    path: indexMQGDnvdRNaMeta?.path ?? "/da/report-tour-numbers",
    meta: indexMQGDnvdRNaMeta || {},
    alias: indexMQGDnvdRNaMeta?.alias || [],
    redirect: indexMQGDnvdRNaMeta?.redirect,
    component: () => import("/vercel/path0/pages/report-tour-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQGDnvdRNaMeta?.name ?? "report-tour-numbers___it",
    path: indexMQGDnvdRNaMeta?.path ?? "/it/report-tour-numbers",
    meta: indexMQGDnvdRNaMeta || {},
    alias: indexMQGDnvdRNaMeta?.alias || [],
    redirect: indexMQGDnvdRNaMeta?.redirect,
    component: () => import("/vercel/path0/pages/report-tour-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45joiningvvUmj3acUPMeta?.name ?? "thanks-for-joining___en___default",
    path: thanks_45for_45joiningvvUmj3acUPMeta?.path ?? "/thanks-for-joining",
    meta: thanks_45for_45joiningvvUmj3acUPMeta || {},
    alias: thanks_45for_45joiningvvUmj3acUPMeta?.alias || [],
    redirect: thanks_45for_45joiningvvUmj3acUPMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-joining.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45joiningvvUmj3acUPMeta?.name ?? "thanks-for-joining___en",
    path: thanks_45for_45joiningvvUmj3acUPMeta?.path ?? "/en/thanks-for-joining",
    meta: thanks_45for_45joiningvvUmj3acUPMeta || {},
    alias: thanks_45for_45joiningvvUmj3acUPMeta?.alias || [],
    redirect: thanks_45for_45joiningvvUmj3acUPMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-joining.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45joiningvvUmj3acUPMeta?.name ?? "thanks-for-joining___es",
    path: thanks_45for_45joiningvvUmj3acUPMeta?.path ?? "/es/thanks-for-joining",
    meta: thanks_45for_45joiningvvUmj3acUPMeta || {},
    alias: thanks_45for_45joiningvvUmj3acUPMeta?.alias || [],
    redirect: thanks_45for_45joiningvvUmj3acUPMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-joining.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45joiningvvUmj3acUPMeta?.name ?? "thanks-for-joining___da",
    path: thanks_45for_45joiningvvUmj3acUPMeta?.path ?? "/da/thanks-for-joining",
    meta: thanks_45for_45joiningvvUmj3acUPMeta || {},
    alias: thanks_45for_45joiningvvUmj3acUPMeta?.alias || [],
    redirect: thanks_45for_45joiningvvUmj3acUPMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-joining.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45joiningvvUmj3acUPMeta?.name ?? "thanks-for-joining___it",
    path: thanks_45for_45joiningvvUmj3acUPMeta?.path ?? "/it/thanks-for-joining",
    meta: thanks_45for_45joiningvvUmj3acUPMeta || {},
    alias: thanks_45for_45joiningvvUmj3acUPMeta?.alias || [],
    redirect: thanks_45for_45joiningvvUmj3acUPMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-joining.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45registeringTRXTFvFnXeMeta?.name ?? "thanks-for-registering___en___default",
    path: thanks_45for_45registeringTRXTFvFnXeMeta?.path ?? "/thanks-for-registering",
    meta: thanks_45for_45registeringTRXTFvFnXeMeta || {},
    alias: thanks_45for_45registeringTRXTFvFnXeMeta?.alias || [],
    redirect: thanks_45for_45registeringTRXTFvFnXeMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-registering.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45registeringTRXTFvFnXeMeta?.name ?? "thanks-for-registering___en",
    path: thanks_45for_45registeringTRXTFvFnXeMeta?.path ?? "/en/thanks-for-registering",
    meta: thanks_45for_45registeringTRXTFvFnXeMeta || {},
    alias: thanks_45for_45registeringTRXTFvFnXeMeta?.alias || [],
    redirect: thanks_45for_45registeringTRXTFvFnXeMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-registering.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45registeringTRXTFvFnXeMeta?.name ?? "thanks-for-registering___es",
    path: thanks_45for_45registeringTRXTFvFnXeMeta?.path ?? "/es/thanks-for-registering",
    meta: thanks_45for_45registeringTRXTFvFnXeMeta || {},
    alias: thanks_45for_45registeringTRXTFvFnXeMeta?.alias || [],
    redirect: thanks_45for_45registeringTRXTFvFnXeMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-registering.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45registeringTRXTFvFnXeMeta?.name ?? "thanks-for-registering___da",
    path: thanks_45for_45registeringTRXTFvFnXeMeta?.path ?? "/da/thanks-for-registering",
    meta: thanks_45for_45registeringTRXTFvFnXeMeta || {},
    alias: thanks_45for_45registeringTRXTFvFnXeMeta?.alias || [],
    redirect: thanks_45for_45registeringTRXTFvFnXeMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-registering.vue").then(m => m.default || m)
  },
  {
    name: thanks_45for_45registeringTRXTFvFnXeMeta?.name ?? "thanks-for-registering___it",
    path: thanks_45for_45registeringTRXTFvFnXeMeta?.path ?? "/it/thanks-for-registering",
    meta: thanks_45for_45registeringTRXTFvFnXeMeta || {},
    alias: thanks_45for_45registeringTRXTFvFnXeMeta?.alias || [],
    redirect: thanks_45for_45registeringTRXTFvFnXeMeta?.redirect,
    component: () => import("/vercel/path0/pages/thanks-for-registering.vue").then(m => m.default || m)
  },
  {
    name: calendarHrdVuwaPTtMeta?.name ?? "tours-calendar___en___default",
    path: calendarHrdVuwaPTtMeta?.path ?? "/tours/calendar",
    meta: calendarHrdVuwaPTtMeta || {},
    alias: calendarHrdVuwaPTtMeta?.alias || [],
    redirect: calendarHrdVuwaPTtMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendarHrdVuwaPTtMeta?.name ?? "tours-calendar___en",
    path: calendarHrdVuwaPTtMeta?.path ?? "/en/tours/calendar",
    meta: calendarHrdVuwaPTtMeta || {},
    alias: calendarHrdVuwaPTtMeta?.alias || [],
    redirect: calendarHrdVuwaPTtMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendarHrdVuwaPTtMeta?.name ?? "tours-calendar___es",
    path: calendarHrdVuwaPTtMeta?.path ?? "/es/tours/calendar",
    meta: calendarHrdVuwaPTtMeta || {},
    alias: calendarHrdVuwaPTtMeta?.alias || [],
    redirect: calendarHrdVuwaPTtMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendarHrdVuwaPTtMeta?.name ?? "tours-calendar___da",
    path: calendarHrdVuwaPTtMeta?.path ?? "/da/tours/calendar",
    meta: calendarHrdVuwaPTtMeta || {},
    alias: calendarHrdVuwaPTtMeta?.alias || [],
    redirect: calendarHrdVuwaPTtMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendarHrdVuwaPTtMeta?.name ?? "tours-calendar___it",
    path: calendarHrdVuwaPTtMeta?.path ?? "/it/tours/calendar",
    meta: calendarHrdVuwaPTtMeta || {},
    alias: calendarHrdVuwaPTtMeta?.alias || [],
    redirect: calendarHrdVuwaPTtMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendar24sg232nPPxMeta?.name ?? "tours-calendar2___en___default",
    path: calendar24sg232nPPxMeta?.path ?? "/tours/calendar2",
    meta: calendar24sg232nPPxMeta || {},
    alias: calendar24sg232nPPxMeta?.alias || [],
    redirect: calendar24sg232nPPxMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar2.vue").then(m => m.default || m)
  },
  {
    name: calendar24sg232nPPxMeta?.name ?? "tours-calendar2___en",
    path: calendar24sg232nPPxMeta?.path ?? "/en/tours/calendar2",
    meta: calendar24sg232nPPxMeta || {},
    alias: calendar24sg232nPPxMeta?.alias || [],
    redirect: calendar24sg232nPPxMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar2.vue").then(m => m.default || m)
  },
  {
    name: calendar24sg232nPPxMeta?.name ?? "tours-calendar2___es",
    path: calendar24sg232nPPxMeta?.path ?? "/es/tours/calendar2",
    meta: calendar24sg232nPPxMeta || {},
    alias: calendar24sg232nPPxMeta?.alias || [],
    redirect: calendar24sg232nPPxMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar2.vue").then(m => m.default || m)
  },
  {
    name: calendar24sg232nPPxMeta?.name ?? "tours-calendar2___da",
    path: calendar24sg232nPPxMeta?.path ?? "/da/tours/calendar2",
    meta: calendar24sg232nPPxMeta || {},
    alias: calendar24sg232nPPxMeta?.alias || [],
    redirect: calendar24sg232nPPxMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar2.vue").then(m => m.default || m)
  },
  {
    name: calendar24sg232nPPxMeta?.name ?? "tours-calendar2___it",
    path: calendar24sg232nPPxMeta?.path ?? "/it/tours/calendar2",
    meta: calendar24sg232nPPxMeta || {},
    alias: calendar24sg232nPPxMeta?.alias || [],
    redirect: calendar24sg232nPPxMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/calendar2.vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours___en___default",
    path: indexemJKwxSY7PMeta?.path ?? "/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours___en",
    path: indexemJKwxSY7PMeta?.path ?? "/en/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours___es",
    path: indexemJKwxSY7PMeta?.path ?? "/es/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours___da",
    path: indexemJKwxSY7PMeta?.path ?? "/da/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours___it",
    path: indexemJKwxSY7PMeta?.path ?? "/it/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: indext6uh3LgzeAMeta?.name ?? "tours-manage___en___default",
    path: indext6uh3LgzeAMeta?.path ?? "/tours/manage",
    meta: indext6uh3LgzeAMeta || {},
    alias: indext6uh3LgzeAMeta?.alias || [],
    redirect: indext6uh3LgzeAMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indext6uh3LgzeAMeta?.name ?? "tours-manage___en",
    path: indext6uh3LgzeAMeta?.path ?? "/en/tours/manage",
    meta: indext6uh3LgzeAMeta || {},
    alias: indext6uh3LgzeAMeta?.alias || [],
    redirect: indext6uh3LgzeAMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indext6uh3LgzeAMeta?.name ?? "tours-manage___es",
    path: indext6uh3LgzeAMeta?.path ?? "/es/tours/manage",
    meta: indext6uh3LgzeAMeta || {},
    alias: indext6uh3LgzeAMeta?.alias || [],
    redirect: indext6uh3LgzeAMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indext6uh3LgzeAMeta?.name ?? "tours-manage___da",
    path: indext6uh3LgzeAMeta?.path ?? "/da/tours/manage",
    meta: indext6uh3LgzeAMeta || {},
    alias: indext6uh3LgzeAMeta?.alias || [],
    redirect: indext6uh3LgzeAMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indext6uh3LgzeAMeta?.name ?? "tours-manage___it",
    path: indext6uh3LgzeAMeta?.path ?? "/it/tours/manage",
    meta: indext6uh3LgzeAMeta || {},
    alias: indext6uh3LgzeAMeta?.alias || [],
    redirect: indext6uh3LgzeAMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/manage/index.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordT8SgS9UmTaMeta?.name ?? "update-password___en___default",
    path: update_45passwordT8SgS9UmTaMeta?.path ?? "/update-password",
    meta: update_45passwordT8SgS9UmTaMeta || {},
    alias: update_45passwordT8SgS9UmTaMeta?.alias || [],
    redirect: update_45passwordT8SgS9UmTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordT8SgS9UmTaMeta?.name ?? "update-password___en",
    path: update_45passwordT8SgS9UmTaMeta?.path ?? "/en/update-password",
    meta: update_45passwordT8SgS9UmTaMeta || {},
    alias: update_45passwordT8SgS9UmTaMeta?.alias || [],
    redirect: update_45passwordT8SgS9UmTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordT8SgS9UmTaMeta?.name ?? "update-password___es",
    path: update_45passwordT8SgS9UmTaMeta?.path ?? "/es/update-password",
    meta: update_45passwordT8SgS9UmTaMeta || {},
    alias: update_45passwordT8SgS9UmTaMeta?.alias || [],
    redirect: update_45passwordT8SgS9UmTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordT8SgS9UmTaMeta?.name ?? "update-password___da",
    path: update_45passwordT8SgS9UmTaMeta?.path ?? "/da/update-password",
    meta: update_45passwordT8SgS9UmTaMeta || {},
    alias: update_45passwordT8SgS9UmTaMeta?.alias || [],
    redirect: update_45passwordT8SgS9UmTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordT8SgS9UmTaMeta?.name ?? "update-password___it",
    path: update_45passwordT8SgS9UmTaMeta?.path ?? "/it/update-password",
    meta: update_45passwordT8SgS9UmTaMeta || {},
    alias: update_45passwordT8SgS9UmTaMeta?.alias || [],
    redirect: update_45passwordT8SgS9UmTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/update-password.vue").then(m => m.default || m)
  }
]