import { defineStore } from 'pinia'
import { useSupabaseClient, useSupabaseUser } from '#imports'
import { PermissionGuardName } from '../types/PermissionGuardNameEnum'
import { RoleGuardName } from '~/types/RoleGuardNameEnum'

interface PermissionInterface {
  role: RoleGuardName
  guard_name: PermissionGuardName
}
export const usePermissionsStoreNew = defineStore('permissionsStoreNew', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      all: [] as PermissionInterface[],
    }
  },
  getters: {
    hasLoadedPermissions: (state) => !!state.all.length,
    hasPermission(state) {
      return (guard: PermissionGuardName): boolean => {
        // admins
        // this was set up originally with only companies existing
        // so this guard to check company admin would always go to true
        // we're just limiting to non organisation guard checks for now
        if (
          PermissionGuardName.ORGANISATION_ALL_ACCESS !== guard &&
          state.all?.some(
            (permission: PermissionInterface) =>
              permission.guard_name === PermissionGuardName.ALL_ACCESS
          )
        ) {
          return true
        }
        if (
          PermissionGuardName.ORGANISATION_ALL_ACCESS !== guard &&
          state.all?.some(
            (permission: PermissionInterface) =>
              permission.guard_name === PermissionGuardName.COMPANY_ALL_ACCESS
          )
        ) {
          return true
        }

        return state.all?.some(
          (permission: PermissionInterface) => permission.guard_name === guard
        )
      }
    },
  },
  actions: {
    async updatePermissions() {
      if (this.hasLoadedPermissions) {
        // console.log('permissions already loaded')
        return
      }

      const user = useSupabaseUser()
      const client = useSupabaseClient()
      if (!user.value) {
        return
      }

      const { data, error } = await client
        .from('roles_users')
        .select(
          `
            company: companies (
              is_active
            ),
            organisation: organisations (
              is_active
            ),
            role: roles (
              guard_name,
              permissions (
                  guard_name
              )
            )
          `
        )
        .eq('user_id', user?.value?.id || '0')
      if (!data?.length) {
        return
      }
      data.forEach((userRole) => {
        if (!userRole.company && !userRole.organisation) {
          userRole.role?.permissions.forEach(
            (permission: { guard_name: PermissionGuardName }) => {
              this.all.push({
                role: userRole.role.guard_name,
                guard_name: permission.guard_name,
              })
            }
          )
        }
        if (userRole.company && userRole.company?.is_active !== false) {
          userRole.role?.permissions.forEach(
            (permission: { guard_name: PermissionGuardName }) => {
              this.all.push({
                role: userRole.role.guard_name,
                guard_name: permission.guard_name,
              })
            }
          )
        }
        if (
          userRole.organisation &&
          userRole.organisation?.is_active !== false
        ) {
          userRole.role?.permissions.forEach(
            (permission: { guard_name: PermissionGuardName }) => {
              this.all.push({
                role: userRole.role.guard_name,
                guard_name: permission.guard_name,
              })
            }
          )
        }
      })
    },
  },
})
