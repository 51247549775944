import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import colorMode_h6fiDBiggF from "/vercel/path0/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import maska_UHaKf2z1iQ from "/vercel/path0/plugins/maska.ts";
import vueTelInput_9aliFAzcih from "/vercel/path0/plugins/vueTelInput.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  colorMode_h6fiDBiggF,
  formkitPlugin_pZqjah0RUG,
  i18n_yfWm7jX06p,
  plugin_client_UYiXMU8ZyN,
  chunk_reload_client_UciE0i6zes,
  maska_UHaKf2z1iQ,
  vueTelInput_9aliFAzcih
]