import { defineStore } from 'pinia'
import { useSupabaseClient } from '#imports'
export const useAuthCompanyStore = defineStore('useAuthCompanyStore', {
  state: () => {
    return {
      error: null,
      company: { id: 0, has_active_subscription: false },
      organisation: { id: 0 },
    }
  },
  getters: {
    hasLoadedCompany: (state) => state.company.id || state.organisation.id,
  },
  actions: {
    async fetchCompany() {
      if (this.hasLoadedCompany) {
        return
      }
      const client = useSupabaseClient()
      // RLS dictates that we only have access to our own public.users entry which
      // has the same id as our auth.uid
      const { data, error } = await client
        .from('users')
        .select(
          `
            companies!companies_users (
              id,
              has_active_subscription
            ),
            organisations (
              id
            )
          `
        )
        .limit(1)
      this.company.id = data?.[0]?.companies?.[0]?.id ?? 0
      this.company.has_active_subscription =
        data?.[0]?.companies?.[0]?.has_active_subscription ?? 0
      this.organisation.id = data?.[0]?.organisations?.[0]?.id ?? 0
      this.error = error
    },
  },
})
